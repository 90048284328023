import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "card-title"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  class: "list-left"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  class: "list-right"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_img_content = _resolveComponent("img-content");
  const _component_section_card = _resolveComponent("section-card");
  const _component_el_pagination = _resolveComponent("el-pagination");
  const _component_t_empty = _resolveComponent("t-empty");
  const _component_t_nav_news = _resolveComponent("t-nav-news");
  const _component_t_layout = _resolveComponent("t-layout");
  return _openBlock(), _createBlock(_component_t_layout, {
    "is-white-bg": true,
    "is-header-padding": true,
    class: "label-search-list"
  }, {
    default: _withCtx(() => {
      var _ctx$labelSearchData, _ctx$labelSearchData3, _ctx$labelSearchData4, _ctx$labelSearchData5, _ctx$labelSearchData6;
      return [_ctx.isSpider ? (_openBlock(), _createElementBlock("h1", _hoisted_1, "出海易标签搜索结果页")) : _createCommentVNode("", true), _createElementVNode("section", _hoisted_2, [_createElementVNode("div", _hoisted_3, [!_ctx.labelSearchDataRes.isLoading && (_ctx$labelSearchData = _ctx.labelSearchData) !== null && _ctx$labelSearchData !== void 0 && _ctx$labelSearchData.items.length ? (_openBlock(), _createElementBlock(_Fragment, {
        key: 0
      }, [_createVNode(_component_section_card, {
        title: _ctx.keyword
      }, {
        default: _withCtx(() => {
          var _ctx$labelSearchData2;
          return [(_ctx$labelSearchData2 = _ctx.labelSearchData) !== null && _ctx$labelSearchData2 !== void 0 && _ctx$labelSearchData2.items ? (_openBlock(), _createBlock(_component_img_content, {
            key: 0,
            list: _ctx.labelSearchData.items
          }, null, 8, ["list"])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["title"]), _withDirectives(_createElementVNode("div", null, [_createVNode(_component_el_pagination, {
        "current-page": (_ctx$labelSearchData3 = _ctx.labelSearchData) === null || _ctx$labelSearchData3 === void 0 ? void 0 : _ctx$labelSearchData3.page,
        "page-size": (_ctx$labelSearchData4 = _ctx.labelSearchData) === null || _ctx$labelSearchData4 === void 0 ? void 0 : _ctx$labelSearchData4.page_size,
        layout: "prev, pager, next, jumper",
        total: (_ctx$labelSearchData5 = _ctx.labelSearchData) === null || _ctx$labelSearchData5 === void 0 ? void 0 : _ctx$labelSearchData5.total,
        background: "",
        onCurrentChange: _ctx.handleChangePageaction
      }, null, 8, ["current-page", "page-size", "total", "onCurrentChange"])], 512), [[_vShow, (_ctx$labelSearchData6 = _ctx.labelSearchData) === null || _ctx$labelSearchData6 === void 0 ? void 0 : _ctx$labelSearchData6.total]])], 64)) : !_ctx.labelSearchDataRes.isLoading ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(_component_t_empty, {
        title: "抱歉未找到您想要的信息",
        "is-change-path": true
      })])) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_t_nav_news, {
        "nav-news-cards": _ctx.navCardList
      }, null, 8, ["nav-news-cards"])])])];
    }),
    _: 1
  });
}