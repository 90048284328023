import { defineComponent, computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useHead } from '@vueuse/head';
import TLayout from '@/components/Layout/Layout.vue';
import sectionCard from '@/views/MergeList/components/sectionCard.vue';
import imgContent from '@/views/MergeList/components/imgContent.vue';
import TNavNews from '@/views/News/components/NavNews.vue';
import TEmpty from '@/views/News/components/TEmpty/index.vue';
import { DEFAULT_SEO_CONFIG } from '@/base/config/seo';
import { SeoListMutationEnum } from '@/store/seolist';
import { LabelSearchListMutationEnum } from '@/store/labelsearchlist';
import useStoreRequest from '@/base/hooks/useStoreRequest';
import { getLabelSearchList } from '@/base/api/labelsearchlist';
import { getSeoList } from '@/base/api/seolist';
import { useStore } from '@/store';
export default defineComponent({
  name: 'LabelSearchList',
  components: {
    TLayout,
    TNavNews,
    sectionCard,
    imgContent,
    TEmpty
  },
  setup() {
    const store = useStore();
    const isSpider = computed(() => {
      return store.state.spiderInfo.isSpider;
    });
    /**  ==== 搜索==== start  */
    const route = useRoute();
    const keyword = ref(route.params.keyword || '');
    const {
      state: labelSearchDataRes,
      getData
    } = useStoreRequest({
      space: 'labelsearchlist',
      mutationName: LabelSearchListMutationEnum.SET_LABEL_SEARCH_LIST_DATA,
      stateKey: 'labelSearchListData'
    }, getLabelSearchList, {
      tag: keyword.value
    });
    const labelSearchData = computed(() => {
      return labelSearchDataRes.value.data;
    });
    const handleChangePageaction = currentPage => {
      if (currentPage % 1 !== 0) {
        currentPage = Math.ceil(currentPage);
      }
      getData({
        tag: keyword.value,
        page: currentPage
      });
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    };
    // 特殊符号转义一下, 为了避免 xss, 这里可能不用这样，因为有特殊符号可能就进不来这个页面，以防万一吧 - _ -
    const pageTitle = keyword.value.replace(/[`~!@#$%^&*()=+?/|{}':";,\\[\]<>]/gi, val => {
      return encodeURIComponent(val);
    });
    useHead({
      ...DEFAULT_SEO_CONFIG,
      title: pageTitle || DEFAULT_SEO_CONFIG.title
    });
    /**  ==== 搜索==== end  */
    /**  ==== 右侧资讯文章列表 ==== start  */
    const {
      state: seoListState
    } = useStoreRequest({
      space: 'seolist',
      mutationName: SeoListMutationEnum.SET_SEO_LIST_DATA,
      stateKey: 'seoListData'
    }, getSeoList, {
      term: 'all'
    });
    const all = computed(() => {
      var _seoListState$value;
      return ((_seoListState$value = seoListState.value) === null || _seoListState$value === void 0 || (_seoListState$value = _seoListState$value.data) === null || _seoListState$value === void 0 ? void 0 : _seoListState$value.all) || [];
    });
    const navCardList = computed(() => ({
      seolist: all.value
    }));
    /**  ==== 右侧资讯文章列表 ==== end  */
    return {
      keyword,
      labelSearchDataRes,
      labelSearchData,
      navCardList,
      isSpider,
      handleChangePageaction
    };
  }
});