import {IGetNewsListReq, IGetNewsListRes} from '../interface/news';
import {API} from '@/base/http';

const PATHS = {
    labelsearch: '/news/category',
};

const DEFAULT_PAGE_SIZE = 10;

/**
 * 获取标签搜索列表
 */

export const getLabelSearchList = (params: IGetNewsListReq) => {
    const {page = 1, page_size = DEFAULT_PAGE_SIZE} = params || {};

    return API.get<IGetNewsListRes>(PATHS.labelsearch, {data: {...params, page, page_size}});
};